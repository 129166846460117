<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      :is-full-screen="false"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>

    <v-layout wrap v-else>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <span>Voucher</span>
            <v-spacer></v-spacer>
            <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-btn outlined color="green" @click="dialog = true"> Add </v-btn>
            </v-flex>
          </v-card-title>
        </v-card>
        <div>
          <v-container grid-list-md>
            <v-layout wrap pb-10>
              <v-flex v-for="(item, i) in coupon" :key="i" md6 lg6 pa-4>
                <v-card elevation="5" outlined>
                  <v-layout wrap justify-center px-2 py-5>
                    <v-flex xs12>
                      <v-btn color="red" block
                        ><span style="color: white">{{
                          item.name
                        }}</span></v-btn
                      >
                    </v-flex>
                    <v-layout wrap> </v-layout>
                    <v-flex xs12>
                      <span style="font-size: 14px">Offer Validity</span>
                      <br />
                      <span style="font-weight: bold"
                        >{{ item.validTo.slice(0, 10) }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xl12 md12 sm12 xs12>
                      <span style="font-size: 16px; color: #000000">
                        <b>{{ item.description }}</b>
                      </span></v-flex
                    >
                    <v-flex v-if="item.image">
                      <v-img :src="baseURL + item.image"> </v-img>
                    </v-flex>
                    <v-layout wrap pa-2>
                      <v-flex xs12 sm2 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Gender :
                        </span></v-flex
                      >
                      <v-flex xs12 sm10 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.genderSpecified }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm2 text-left v-if="item.sellerId">
                        <span style="font-size: 14px; color: #000000">
                          Seller :
                        </span></v-flex
                      >
                      <v-flex xs12 sm10 text-right v-if="item.sellerId">
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.sellerId.organization }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Start Date :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.validFrom.slice(0, 10) }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          End Date :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.validTo.slice(0, 10) }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Maximum Redem Count :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.maxRedemCount }} </b>
                        </span></v-flex
                      >
                      <!-- <v-flex xs12 sm2 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Zones :
                        </span></v-flex
                      >
                      <v-flex
                        xs12
                        sm4
                        text-right
                        v-for="(list, i) in item.zoneId"
                        :key="i"
                      >
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ list.name }}</b
                          >,
                        </span></v-flex
                      > -->
                    </v-layout>

                    <v-flex pt-5 px-4 xs6 sm6 md6 lg6 xl6 justify-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Delete
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this 
                           Voucher?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex
                      pt-5
                      px-4
                      pb-2
                      xs6
                      sm6
                      md6
                      lg6
                      xl6
                      justify-center
                    >
                      <v-btn
                        small
                        block
                        class="mr-2"
                        outlined
                        color="green"
                        @click="editSlider(item)"
                      >
                        Edit
                      </v-btn>
                    </v-flex>
                    <br />
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <v-card>
            <v-form v-model="addslider">
              <v-card-title v-if="edit == false">
                <span class="headline">Add Promo Code</span>
              </v-card-title>
              <v-card-title v-else>
                <span class="headline">Edit Promo Code</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="voucherData.name"
                        label="Name"
                        :rules="Rules"
                        required
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="12">
                      <v-select
                        dense
                        color="#FF1313"
                        label="Regions (Pincodes)"
                        outlined
                        multiple
                        v-model="pincode"
                        :items="pincodeArray"
                        item-text="name"
                        item-value="_id"
                      ></v-select>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="12" md="12">
                      <v-select
                        multiple
                        dense
                        color="#FF1313"
                        label="Zones"
                        v-model="voucherData.zone"
                        :items="zoneArray"
                        item-text="name"
                        item-value="_id"
                        outlined
                      ></v-select>
                    </v-col> -->
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="voucherData.gender"
                        :items="items"
                        label="Gender"
                        :rules="Rules"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="voucherData.description"
                        label="Description"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="voucherData.validFrom"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="voucherData.validFrom"
                            label="Select From Date"
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="voucherData.validFrom"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(voucherData.validFrom)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="voucherData.validTo"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="voucherData.validTo"
                            label="Select To Date"
                            outlined
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="voucherData.validTo"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(voucherData.validTo)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="voucherData.sellerId"
                        :items="sellerArray"
                        item-text="organization"
                        item-value="_id"
                        item-color="#8d8d8d"
                        color="#8d8d8d"
                        outlined
                        dense
                        label="Select Seller"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="voucherData.maxRedemCount"
                        label="Maximum Redem Count "
                        :rules="Rules"
                        required
                        dense
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-layout wrap>
                    <v-flex xs12 md12 lg12>
                      <ImageComp
                        :singleImage="voucherData.image"
                        :pageId="voucherData._id"
                        @stepper="winStepper"
                        :heading="'Upload Image'"
                        :componentType="'image'"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="(dialog = false), (voucherData = '')"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="edit == false"
                  color="blue darken-1"
                  text
                  :disabled="!addslider"
                  @click="add()"
                >
                  Add
                </v-btn>
                <v-btn
                  v-else
                  color="blue darken-1"
                  text
                  :disabled="!addslider"
                  @click="editItem()"
                >
                  Edit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      code: null,
      widthOfCard: "250px",
      addslider: false,
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      editdialog: false,
      imgId: "",
      editingitem: "",
      menu1: false,
      menu: false,
      menu3: false,
      menu4: false,
      coupon: [],
      radios: null,
      edit: false,
      sellerArray: [],
      zoneArray: [],
      pincodeArray: [],
      pincode: "",
      voucherData: {
        name: null,
        zone: [],
        description: "",
        validFrom: new Date().toISOString().substr(0, 10),
        validTo: new Date().toISOString().substr(0, 10),
        maxRedemCount: "",
        sellerId: "",
        gender: null,
      },
      items: ["All", "Male", "Female"],
      editId: "",
      count: 12,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      Rules: [(value) => !!value || "Required."],
      reason: null,
      remove: false,
      image: "",
      newZoneArray: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getSeller();
    this.getZone();
    this.getPincodes();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.image = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/rewards/List",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.count,
          page: this.currentPage,
          filter: this.filter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.coupon = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getPincodes() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/pincode/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.pincodeArray = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSeller() {
      this.appLoading = true;
      axios({
        url: "/seller/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.sellerArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getZone() {
      this.appLoading = true;
      axios({
        url: "/zone/List/Reward",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.zoneArray = response.data.data;
          // for(var i=0; i<this.zoneArray.length; i++)
          // {
          //   var newData={};
          //  newData =this.zoneArray[i].pincodes
          //   this.newZoneArray.push(newData)
          //   console.log("pincodes",this.newZoneArray)
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    add() {
      axios({
        url: "/add/Reward",
        method: "POST",
        data: this.voucherData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.imgId = response.data.id;
            this.uploadImage(response.data.id);
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.voucherData = "";
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    deleteItem(id) {
      var data = {};
      data["id"] = id._id;
      axios({
        url: "/delete/Reward",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialogDelete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editSlider(item) {
      this.edit = true;
      this.voucherData = item;
      this.dialog = true;
      this.voucherData.zone = this.voucherData.zoneId;
      this.voucherData.gender = this.voucherData.genderSpecified;
      this.voucherData.validFrom = this.voucherData.validFrom.slice(0, 10);
      this.voucherData.validTo = this.voucherData.validTo.slice(0, 10);
      this.editId = item._id;
    },
    editItem() {
      this.appLoading = true;
      this.voucherData.id = this.editId;
      axios({
        method: "POST",
        url: "/edit/Reward",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.voucherData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.image) {
              this.uploadImage(this.voucherData._id);
            }
            this.getData();
            this.msg = "Edited Sucessfully";
            this.voucherData = "";
            this.showsnackbar = true;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.image);

      axios({
        method: "POST",
        url: "/reward/Upload/Image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
